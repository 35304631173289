<!--
 * @Author: your name
 * @Date: 2021-11-30 10:27:06
 * @LastEditTime: 2022-01-04 17:47:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sproutedus\src\views\product\page1.vue
-->
<template>
  <div id="page1">
    <!-- 切换内容区 -->
    <div class="contain contain1 animated">
      <div class="clientContain">
        <div class="clientContain-center animated" data-aos="fade-up">
          <div class="clientContain-introduce">
            <div class="clientIntroduce-title">
              <h3 class="animated" data-aos="fade-up-right" data-aos-delay="800">年级选择</h3>
              <img
                class="animated"
                data-aos="fade-up"
                data-aos-delay="500"
                src="../../assets/line1.png"
                alt
              />
            </div>
            <p
              class="animated"
              data-aos="fade-up-right"
              style="width: 196px;"
              data-aos-delay="1100"
            >任意切换年级，推荐对应年级课程</p>
          </div>
          <div class="clientContain-introduce">
            <div class="clientIntroduce-title">
              <h3 class="animated" data-aos="fade-up-right" data-aos-delay="1700">学习专栏</h3>
              <img
                class="animated"
                data-aos="fade-up"
                src="../../assets/line2.png"
                data-aos-delay="1400"
                alt
              />
            </div>
            <p class="animated" data-aos="fade-up-right" data-aos-delay="2000">小学全科同步辅导，轻松学习没烦恼！</p>
          </div>
          <div class="clientContain-introduce">
            <div class="clientIntroduce-title">
              <img
                class="animated"
                data-aos="fade-up"
                data-aos-delay="2300"
                src="../../assets/line3.png"
                alt
              />
              <h3 class="animated" data-aos="fade-up-left" data-aos-delay="2600">辅助学习小应用</h3>
            </div>
            <p class="animated" data-aos="fade-up-left" data-aos-delay="2900">开启娱乐刷题测验模式，提分更高效。</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="section">
        <div class="sectionContain">
          <div class="sectionChild" data-aos="fade-up" data-aos-once="true">
            <div class="sectionChild-content">
              <h3>投屏模式</h3>
              <p>手机、电视双屏切换，大屏学习，高清观看，给你带来一个互动式的课堂。</p>
              <p>【超清投屏 稳定连接】</p>
              <p>手机轻松投屏电脑/电视 画面高清/流畅/低延迟</p>
              <p>简单操作 3步投屏</p>
              <p>1. 点击【投屏】按钮</p>
              <p>2. 手机通过数据线或wifi进行连接</p>
              <p>3. 成功连接；即可开启大屏体验</p>
            </div>
            <div class="sectionChild-img">
              <img src="../../assets/show2.png" alt />
            </div>
          </div>
          <div class="sectionChild" data-aos="fade-up" data-aos-once="true">
            <div class="sectionChild-img">
              <img src="../../assets/show3.png" alt />
            </div>
            <div class="sectionChild-content">
              <h3>VIP会员</h3>
              <p>海量课程，随时观看，同步测验，学习更高效。</p>
              <p>新人折扣优惠，打卡/任务/积分/兑换...</p>
              <p>更多超值体验等你发现！</p>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="other" data-aos="fade-up" data-aos-once="true">
        <div class="otherContain">
          <div class="otherContain-title">其他功能</div>
          <div
            class="otherContain-text"
          >涵盖多个版本的小学、同步精讲课程。课程为名师精讲，同步点拨！提纲契要，重难点，典型题透讲，真题链接，直击考试要点。各科配有同步课程视频，按每课的学习重点、学习目标、知识精讲、典型题，真题链接模块来讲，拓展解析和点拨，让学生快速掌握每课的精髓，并结合题目巩固知识。</div>
          <div class="otherContain-power">
            <div class="powerItem">辅助小应用</div>
            <div class="powerItem">积分商城</div>
            <div class="powerItem">个人中心</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'page1',
}
</script>
<style lang="scss">
@import "../../../static/common.scss";
.clientContain {
  width: 100%;
  height: 840px;
  background-color: #f4f7f9;
  padding-top: 47px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  &-center {
    width: 461px;
    height: 752px;
    position: relative;
    background-image: url(../../assets/show1.png);
    background-size: 100% 100%;
    .clientContain-bg {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .clientContain-introduce {
      position: absolute;
      top: 96px;
      z-index: 9;
      .clientIntroduce-title {
        @include display(center, left);
        h3 {
          font-size: 24px;
          font-weight: bold;
          line-height: 41px;
          color: #000000;
        }
        img {
          display: block;
          width: 120.2px;
          height: 12px;
          margin-left: 56px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #6e6e6e;
      }
    }
    .clientContain-introduce:nth-child(1) {
      left: -136px;
    }
    .clientContain-introduce:nth-child(2) {
      top: 373px;
      left: -187px;
      .clientIntroduce-title {
        img {
          margin-left: 59px;
          width: 168px;
        }
      }
      p {
        width: 203px;
      }
    }
    .clientContain-introduce:nth-child(3) {
      top: 265px;
      right: -338px;
      width: 404px;
      .clientIntroduce-title {
        img {
          width: 168px;
          margin-right: 11px;
          margin-left: 0;
        }
      }
      p {
        width: 226px;
        float: right;
      }
    }
  }
}
.section {
  width: 100%;
  @include display(left, center);
  &Contain {
    .sectionChild {
      animation-duration: 500ms;
      @include display(left, between);
      &-content {
        width: 492px;
        margin-left: 36px;
        h3 {
          font-size: 28px;
          font-weight: bold;
          margin: 173px 0 36px;
          color: #1b215d;
          line-height: 28px;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          color: #6e6e6e;
          // margin-bottom: 28px;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
      &-img {
        width: 688px;
        height: 528px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .sectionChild:nth-child(1) {
      margin: -45px 0 81px;
    }
    .sectionChild:last-child {
      margin-bottom: 191px;
      .sectionChild-content {
        h3 {
          margin: 199px 0 36px;
        }
      }
    }
  }
}
.other {
  width: 100%;
  // height: 840px;
  padding-bottom: 109px;
  background: #f4f7f9;
  @include display(left, center);
  &Contain {
    width: 1200px;
    text-align: center;
    padding-top: 80px;
    &-title {
      display: inline-block;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #000000;
      text-align: center;
      position: relative;
    }
    &-title::before {
      position: absolute;
      top: 50%;
      left: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleLeft.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(-50%);
    }
    &-title::after {
      position: absolute;
      top: 50%;
      right: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleRight.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(-50%);
    }
    &-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #000000;
      text-align: left;
      margin-bottom: 50px;
    }
    &-power {
      width: 1200px;
      height: 507px;
      background-image: url(../../assets/show4.png);
      background-size: 100% 100%;
      display: flex;
      .powerItem {
        width: 120px;
        height: 49px;
        background-image: url(../../assets/buttonBg.png);
        background-size: 100% 100%;
        padding-top: 12px;
        box-sizing: border-box;
        text-align: center;
        font-size: 16px;
        line-height: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        margin: 4px 0 0 196px;
      }
      .powerItem:nth-child(2) {
        margin: 0 0 0 228px;
      }
      .powerItem:nth-child(3) {
        margin: 19px 0 0 233px;
      }
    }
  }
}
.contain2 {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.bigTitle {
  width: 100%;
  height: 500px;
  background: #4967ff;
  &-contain {
    width: 1200px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
    h3 {
      font-size: 56px;
      font-weight: bold;
      line-height: 95px;
      color: #ffffff;
      padding: 96px 0 16px 30px;
    }
    p {
      width: 564px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 28px;
      color: #ffffff;
      opacity: 0.8;
      padding-left: 30px;
      box-sizing: border-box;
    }
    ul {
      margin-top: 39px;
      padding-left: 51px;
      li {
        font-size: 24px;
        font-weight: 500;
        line-height: 41px;
        color: #ffffff;
      }
    }
    .appBg {
      position: absolute;
      top: 110px;
      right: -139px;
      width: 900px;
      height: 643px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.appIntrodoce {
  width: 1200px;
  margin: 0 auto;
  height: 340px;
  &-left {
    display: inline-block;
    width: 142px;
    height: 142px;
    margin: 39px 0 0 4px;
    vertical-align: top;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-right {
    display: inline-block;
    width: 368px;
    margin: 66px 0 0 8px;
    vertical-align: top;
    h3 {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
    }
    p {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 27px;
      color: #5e6577;
      margin-top: 11px;
      @include textOF(3);
    }
  }
  &-item {
    display: inline-block;
    width: 571px;
    height: 106px;
    z-index: 3;
    margin: 34px 0 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.appOffice {
  width: 100%;
  background-color: #f4f7f9;
  padding-bottom: 98px;
  .officeContain {
    text-align: center;
    padding-top: 100px;
    h3 {
      display: inline-block;
      font-size: 38px;
      font-weight: bold;
      color: #000000;
      position: relative;
    }
    h3::before {
      position: absolute;
      top: 50%;
      left: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleLeft.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(50%);
    }
    h3::after {
      position: absolute;
      top: 50%;
      right: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleRight.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(50%);
    }
    p {
      // width: 732px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #5e6577;
      text-align: center;
      margin: 68px auto 0;
      b {
        font-size: 40px;
        color: #41cf14;
      }
    }
  }
  .officeContain-img {
    width: 1130px;
    height: 510px;
    margin: 100px auto 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.contain {
  animation-duration: 500ms;
}
</style>